import "@ryangjchandler/alpine-clipboard";

import 'alpine-magic-helpers';
import 'alpinejs';

export default function wojoNav () {
    return {
        active: '',
        navOpen: false,
        isMobile: window.matchMedia('(max-width: 1024px)').matches,
        isStuck: false,
        hasSecondary: !document.querySelector(".FibroidSecondary").classList.contains('hidden'),
        stickyWatchEl: ".StickyWatch",
        init() {
            // this.isMobile = (window.innerWidth <= 1024) ? true : false;
            
            /* window.matchMedia('(max-width: 1024px)').onchange = function (e) {
                this.isMobile = e.matches;
            } */

            window.matchMedia('(max-width: 1024px)').addEventListener('change', function (e) {
                this.isMobile = e.matches;
            });

            let observer = new IntersectionObserver((entries) => {
                this.isStuck = !entries[0].isIntersecting;
            });
            observer.observe( document.querySelector(this.stickyWatchEl) );

        },

        
        mouseOver(event) {
            // active = 'nav{{ $menuItem->ID }}';
            if(!this.isMobile) {
                this.active = event.currentTarget.dataset.which;
            } 
        },

        click(event) {
            if(this.isMobile) {
                if(this.active != event.currentTarget.dataset.which) {
                    this.active = event.currentTarget.dataset.which;
                } else {
                    this.active = '';
                }
                
            }
        },

        mobileOpen() {
            console.log(this.isMobile);
            if(this.isMobile) {
                this.navOpen = !this.navOpen;
            }
            
        }
    }
}

window.wojoNav = wojoNav;